<app-dialog-wrapper
  [title]="'Main Project Group Settings'"
  [showButtons]="false"
  [enableScroll]="true"
  [saveButtonTestId]="testIds.MainProjectDialogSaveButton"
  [cancelButtonTestId]="testIds.MainProjectDialogCancelButton"
  (cancelClick)="onCancel()"
>
  <div class="px-56 py-24 flex-column" flexGap="16px">
    <div class="flex-row" flexGap="16px">
      <div class="flex-column flex">
        <ngx-q360-text-input
          [testId]="testIds.MainProjectDialogNameInput"
          placeholder="Main project group name"
          label="Main project group name"
          [formControl]="nameControl"
          inputType="input"
          [error]="nameControl.touched && nameControl.invalid"
          (saved)="onSaved(nameControl)"
        />
        <ngx-q360-input-message [control]="nameControl" />
      </div>

      <div class="flex-column">
        <ngx-q360-text-input
          [testId]="testIds.MainProjectDialogShortNameInput"
          placeholder="2-5 characters"
          label="Short name"
          [formControl]="codeControl"
          inputType="input-read-only"
          [error]="codeControl.touched && codeControl.invalid"
        />
        <ngx-q360-input-message [control]="codeControl" />
      </div>
    </div>

    <mat-divider />

    <mat-tab-group animationDuration="0ms">
      <mat-tab label="Main project group ownership">
        <div class="flex-column mt-16" flexGap="16px">
          <ngx-q360-load-more [show]="mainProjectLoading()" />
          @if (!mainProjectLoading()) {
            <!--owner-->
            <div class="flex-column" flexGap="16px">
              <div class="flex-row" flexLayoutAlign="space-between center">
                <div class="headlines-subtitle1 greyscale-darkest-grey">Owner</div>
                @if (ownerAdmins(); as admins) {
                  @if (admins.length) {
                    <div class="flex-row" flexGap="8px">
                      <ngx-q360-avatar
                        [sasUrl]="sasUrl()"
                        [avatar]="{
                          photoUrl: admins[0].organizationPhotoUrl,
                          name: admins[0].organizationName
                        }"
                      />
                      <div class="labels-label-l greyscale-body-text-grey">
                        {{ admins[0].organizationName }}
                      </div>
                    </div>
                  } @else {
                    <div
                      class="labels-label-l greyscale-idle-grey flex-row"
                      flexGap="8px"
                      flexLayoutAlign="start center"
                    >
                      <svg class="svg-24" svgIcon="framed-help-questionmark" />
                      Owner missing
                    </div>
                  }
                }
              </div>
              <div class="secondary-8-blue-8-bg p-16 flex-row br-8" flexGap="8px">
                <div>
                  <svg class="svg-16 primary-colors-accent-blue" svgIcon="framed-info" />
                </div>
                <div>
                  <div class="headlines-subtitle-all-caps greyscale-darkest-grey">Owning a main project group</div>
                  <ul class="labels-label-m greyscale-darkest-grey pl-20">
                    <li>
                      The owner of the main project group is the client for all projects contained in that main project
                      group.
                    </li>
                    <li>
                      Users specified here have the ability to manage this setting, and settings of projects inside this
                      main project group.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            @if (ownerAdmins(); as admins) {
              @if (admins.length) {
                <div class="headlines-subtitle2 greyscale-light-text">Main Project Group Admins</div>
              }

              @for (admin of admins; track admin.id) {
                <div class="flex-row py-8 px-12" flexGap="8px" flexLayoutAlign="start center">
                  @if (admin.status === mainProjectAdminStatus.Invited) {
                    <svg class="svg-24 greyscale-idle-grey" svgIcon="time-hourglass" />
                  } @else {
                    <ngx-q360-avatar
                      [sasUrl]="sasUrl()"
                      size="avatar-circle"
                      [avatar]="{
                        photoUrl: admin.photoUrl,
                        name: admin.name
                      }"
                    />
                  }
                  <div class="flex-column flex">
                    <div class="labels-label-l greyscale-body-text-grey">
                      {{ admin.status === mainProjectAdminStatus.Invited ? admin.email : admin.name }}
                    </div>
                    @if (admin.status === mainProjectAdminStatus.Active) {
                      <div class="labels-label-s greyscale-light-text">Main project group admin</div>
                    }
                  </div>
                  @if ((admins.length > 1 && admin.userId !== user()?.id) || invitedOwnerAdmins().length) {
                    <svg
                      class="cursor-pointer svg-24 primary-colors-accent-blue"
                      svgIcon="basic-close"
                      (click)="onRemoveAdmin(admin)"
                    />
                  }
                </div>
              }
            }

            @if (user()?.organizationId === mainProject()?.ownerOrganizationId) {
              <div class="flex-column" flexGap="8px">
                <ngx-q360-select-input
                  [sasUrl]="sasUrl()"
                  placeholder="Add a main project group admin"
                  inputType="input"
                  [formControl]="userControl"
                  [withSearch]="true"
                  [items]="availableOwnerAdmins()"
                />
                <button
                  [disabled]="userControl.invalid"
                  type="button"
                  class="primary-button flex-align-self-end"
                  (click)="handleOwnerAdmin()"
                >
                  Add
                </button>
              </div>
            }

            @if (!mainProject()?.ownerOrganizationId && !invitedOwnerAdmins().length) {
              <mat-accordion class="plain-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title class="p-0">
                      <div class="flex-column">
                        <div class="labels-label-m primary-colors-accent-blue">Invite owner</div>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="b br-16 flex-row overflow-hidden">
                    <div class="p-24 br flex flex-column" flexGap="16px">
                      <div class="headlines-subtitle1">Invite owner</div>
                      <div class="flex-column" flexGap="16px">
                        <div class="flex-column">
                          <app-select-auto-complete-users-async
                            [sasUrl]="sasUrl()"
                            [formControl]="searchUsers"
                            label="Send to"
                            placeholder="Enter an email or search for users"
                            [options]="users()"
                            [loading]="usersLoading()"
                            (valueChange)="onSearchUsers($event, true)"
                          />
                          <ngx-q360-input-message [control]="inviteOwnerControls.email" />
                        </div>
                        <div class="flex-column">
                          <div class="flex-column">
                            <app-pm-long-text-input
                              [formControl]="inviteOwnerControls.message"
                              inputType="input"
                              placeholder="Write a message"
                              label="Custom message (optional)"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="p-24 flex greyscale-background-grey-bg flex-column" flexGap="16px">
                      <div class="headlines-subtitle1">Invitation Preview</div>
                      <div class="flex-column p-16 card" flexGap="24px">
                        <div class="paragraph-body-2">
                          You’ve been invited to be the owner of <strong>{{ mainProject()?.name }}</strong> and the
                          client in all projects within it.
                        </div>
                        <div class="flex-row greyscale-light-text" flexGap="8px">
                          <svg class="svg-16" svgIcon="framed-info" />
                          <div class="labels-label-m">
                            {{ callerAdmin()?.organizationName }} currently co-owns this main project group.
                          </div>
                        </div>
                        <div class="flex-column">
                          <div class="labels-label-s greyscale-light-text">Invitation by</div>
                          <div class="labels-label-m greyscale-body-text-grey">{{ callerAdmin()?.name }}</div>
                          <div class="labels-label-s greyscale-light-text">{{ callerAdmin()?.organizationName }}</div>
                        </div>

                        @if (inviteOwnerControls.message.value) {
                          <div class="flex-column">
                            <div class="labels-label-s greyscale-light-text">Message</div>
                            <div class="labels-label-m greyscale-body-text-grey">
                              {{ inviteOwnerControls.message.value }}
                            </div>
                          </div>
                        }
                      </div>
                      <button
                        [disabled]="isSendInviteDisabled"
                        class="primary-button large flex-align-self-end"
                        (click)="handleOwnerAdmin()"
                      >
                        Send invitation
                      </button>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            }

            <mat-divider />

            <!--co-owner-->
            <div class="flex-column" flexGap="16px">
              <div class="flex-row" flexLayoutAlign="space-between center">
                <div class="headlines-subtitle1 greyscale-darkest-grey">Co-Owner</div>
                @if (coOwnerAdmins(); as admins) {
                  @if (admins.length) {
                    <div class="flex-row" flexGap="8px">
                      <ngx-q360-avatar
                        [sasUrl]="sasUrl()"
                        [avatar]="{
                          photoUrl: admins[0].organizationPhotoUrl,
                          name: admins[0].organizationName
                        }"
                      />
                      <div class="labels-label-l greyscale-body-text-grey">
                        {{ admins[0].organizationName }}
                      </div>
                    </div>
                  }
                }
              </div>
              <div class="secondary-8-blue-8-bg p-16 flex-row br-8" flexGap="8px">
                <div>
                  <svg class="svg-16 primary-colors-accent-blue" svgIcon="framed-info" />
                </div>
                <div>
                  <div class="headlines-subtitle-all-caps greyscale-darkest-grey">Co-Owning a main project group</div>
                  <ul class="labels-label-m greyscale-darkest-grey pl-20">
                    @if (dialogData()?.mainProject?.mainProjectType === mainProjectType.InHouse) {
                      <li>Only paid projects that are set up for collaboration can have a co-owner.</li>
                    } @else {
                      <li>
                        Users specified here have admin rights and are able to create new projects inside this main
                        project group.
                      </li>
                    }
                  </ul>
                </div>
              </div>
            </div>

            @if (dialogData()?.mainProject?.mainProjectType !== mainProjectType.InHouse) {
              <div class="paragraph-body">
                @if (!invitedCoOwnerAdmins().length) {
                  Invite a person from another organization to co-own this main project group.
                } @else {
                  Main Project Group Admins
                }
              </div>

              @if (coOwnerAdmins(); as admins) {
                @for (admin of admins; track admin.id) {
                  <div class="flex-row py-8 px-12" flexGap="8px" flexLayoutAlign="start center">
                    @if (admin.status === mainProjectAdminStatus.Invited) {
                      <svg class="svg-24 greyscale-idle-grey" svgIcon="time-hourglass" />
                    } @else {
                      <ngx-q360-avatar
                        size="avatar-circle"
                        [sasUrl]="sasUrl()"
                        [avatar]="{
                          photoUrl: admin.photoUrl,
                          name: admin.name
                        }"
                      />
                    }
                    <div class="flex-column flex">
                      <div class="labels-label-l greyscale-body-text-grey">
                        {{ admin.status === mainProjectAdminStatus.Invited ? admin.email : admin.name }}
                      </div>
                      @if (admin.status === mainProjectAdminStatus.Active) {
                        <div class="labels-label-s greyscale-light-text">Main project group admin</div>
                      }
                    </div>
                    @if (
                      (admins.length > 1 && admin.userId !== user()?.id) ||
                      (admins | filter: mainProjectAdminStatus.Invited : ['status']).length ||
                      dialogData()?.mainProject?.ownerOrganizationId === user()?.organizationId
                    ) {
                      <svg
                        class="cursor-pointer svg-24 primary-colors-accent-blue"
                        svgIcon="basic-close"
                        (click)="handleAdminRemoval(admin)"
                      />
                    }
                  </div>
                }
              }

              @if (user()?.organizationId === mainProject()?.coOwnerOrganizationId || !coOwnerAdmins().length) {
                <div class="flex-column" flexGap="8px">
                  @if (coOwnerAdmins().length) {
                    <ngx-q360-select-input
                      placeholder="Add a main project group admin"
                      inputType="input"
                      [formControl]="userControl"
                      [withSearch]="true"
                      [sasUrl]="sasUrl()"
                      [items]="availableCoOwnerAdmins()"
                    />
                  } @else {
                    <app-select-auto-complete-users-async
                      [testId]="testIds.ProjectTeamInviteCollaboratorSendToInput"
                      [formControl]="searchUsers"
                      label="Send to"
                      placeholder="Enter an email or search for users"
                      [options]="users()"
                      [loading]="usersLoading()"
                      [sasUrl]="sasUrl()"
                      (valueChange)="onSearchUsers($event, false)"
                    />
                    <ngx-q360-input-message [control]="inviteOwnerControls.email" />
                  }

                  <button
                    [disabled]="coOwnerAdmins().length ? userControl.invalid : isSendInviteDisabled"
                    type="button"
                    class="primary-button flex-align-self-end"
                    (click)="handleCoOwnerAdmin()"
                  >
                    {{ coOwnerAdmins().length ? 'Add' : 'Send an invitation' }}
                  </button>
                </div>
              }
            }
          }
        </div>
      </mat-tab>
      <mat-tab label="Subscription ownership">
        <div class="flex-column mt-16" flexGap="16px">
          <div class="paragraph-body-2 greyscale-light-text">
            The admins from the organizations you specify here will have access to the
            <strong>Pricing Plan</strong> option in the <strong>Project Settings</strong>.
          </div>

          <div class="paragraph-body-2 greyscale-light-text">
            You can choose from the organizations that are collaborating on the project. To add collaborators, navigate
            to a project and then its Team option in the menu.
          </div>

          <mat-divider />

          <ngx-q360-load-more [show]="mainProjectLoading()" />

          @if (!mainProjectLoading()) {
            @for (project of ownerSubscriptionsArray.controls; track project.controls.id.value) {
              <div class="flex-row" flexLayoutAlign="space-between center">
                <div class="flex-column flex">
                  <div class="labels-label-s greyscale-idle-grey">{{ project.get('code')?.value }}</div>
                  <div class="headlines-subtitle1 greyscale-darkest-grey">{{ project.get('name')?.value }}</div>
                </div>
                <div class="flex" [formGroup]="project">
                  <ngx-q360-select-input
                    placeholder="Select organisation"
                    inputType="input"
                    formControlName="id"
                    [withSearch]="false"
                    [showAvatar]="true"
                    [sasUrl]="sasUrl()"
                    [items]="project.get('collaborators')?.value || []"
                  />
                </div>
              </div>
            } @empty {
              <div class="labels-label-s greyscale-idle-grey">No projects found in this main project group.</div>
            }
          }

          @if (unSavedChanges) {
            <div class="b br-8 secondary-8-orange-8-bg p-16">
              <div class="flex-row" flexGap="8px">
                <svg class="svg-16 primary-colors-orange" svgIcon="framed-alert-triangle" />
                <div class="flex-column" flexGap="8px">
                  <div class="headlines-subtitle-all-caps greyscale-darkest-grey">Reset Subscription</div>
                  <div class="labels-label-m greyscale-darkest-grey">
                    Changing the ownership will take affect after the current payment cycle is completed.
                    <br />
                    <br />
                    The current owner will lose access to the Pricing Plan setting, and the project will be downgraded
                    to Free.
                  </div>
                  <div class="flex-row" flexLayoutAlign="end center">
                    <button class="tertiary-button" type="button" (click)="onCancelOwnerShipChange()">Cancel</button>
                    <button class="tertiary-button" type="button" (click)="onConfirmOwnerShipChange()">
                      Confirm changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</app-dialog-wrapper>
