import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  DestroyRef,
  Inject,
  inject,
  OnInit,
  signal,
} from '@angular/core';
import {
  AvatarComponent,
  CollaboratorModel,
  CollaboratorState,
  CollaboratorType,
  FilterPipe,
  FlexGapDirective,
  FlexLayoutAlignDirective,
  FormUtility,
  InputMessageComponent,
  InviteMainProjectAdminPayload,
  LoadMoreComponent,
  MainProjectAdmin,
  MainProjectAdminPayload,
  MainProjectAdminStatus,
  MainProjectModel,
  MainProjectType,
  OrganizationMainProject,
  PmSelectInputComponent,
  PmTextInputComponent,
  select,
  SvgIconDirective,
  TestIdDirective,
  TestIds,
} from 'ngx-q360-lib';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  NonNullableFormBuilder,
  ReactiveFormsModule,
} from '@angular/forms';
import { UserSelectors } from '@app/store/user/user.selectors';
import { Store } from '@ngxs/store';
import { OrganisationSelectors } from '@app/store/organisation/organisation.selectors';
import {
  AddProjectMainAdmin,
  GetMainProject,
  InviteProjectMainAdmin,
  RemoveCoOwner,
  RemoveProjectMainAdmin,
  UpdateMainProject,
} from '@app/store/project/project.actions';
import { ConfirmDialogComponent } from '@global-shared/dialogs/confirm-dialog/confirm-dialog.component';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import { ProjectSelectors } from '@app/store/project/projects.selectors';
import { isEqual } from 'lodash';
import { ProjectTeamSelectors } from '@project/store/team/project-team.selectors';
import { SetUsersQuery } from '@project/store/team/project-team.actions';
import { organisationsUsersQuery } from '@core/helpers/query.helper';
import { UpdateProject } from '@project/store/selected-project/selected-project.actions';
import { v4 as uuidv4 } from 'uuid';
import { PmLongTextInputComponent } from '../../form-controls/pm-long-text-input/pm-long-text-input.component';
import { SelectAutoCompleteUsersAsyncComponent } from '../../form-controls/select-auto-complete-users-async/select-auto-complete-users-async.component';
import {
  MatAccordion,
  MatExpansionPanel,
  MatExpansionPanelHeader,
  MatExpansionPanelTitle,
} from '@angular/material/expansion';
import { MatTab, MatTabGroup } from '@angular/material/tabs';
import { MatDivider } from '@angular/material/divider';
import { DialogWrapperComponent } from '../../components/dialog-wrapper/dialog-wrapper.component';

interface DialogData {
  mainProject: MainProjectModel | OrganizationMainProject;
}

@Component({
  selector: 'app-main-project-settings',
  templateUrl: './main-project-settings.component.html',
  styleUrls: ['./main-project-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DialogWrapperComponent,
    FlexGapDirective,
    PmTextInputComponent,
    TestIdDirective,
    FormsModule,
    ReactiveFormsModule,
    InputMessageComponent,
    MatDivider,
    MatTabGroup,
    MatTab,
    LoadMoreComponent,
    FlexLayoutAlignDirective,
    AvatarComponent,
    PmSelectInputComponent,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    SelectAutoCompleteUsersAsyncComponent,
    PmLongTextInputComponent,
    FilterPipe,
    SvgIconDirective,
  ],
})
export class MainProjectSettingsComponent implements OnInit {
  protected readonly mainProjectAdminStatus = MainProjectAdminStatus;
  protected readonly mainProjectType = MainProjectType;
  protected readonly testIds = TestIds;

  protected readonly matDialogRef = inject(MatDialogRef<MainProjectSettingsComponent>);
  protected readonly store = inject(Store);
  private readonly matDialog = inject(MatDialog);
  private readonly fb = inject(NonNullableFormBuilder);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);

  readonly mainProject$ = select(ProjectSelectors.mainProject);

  users = toSignal(select(ProjectTeamSelectors.users), { initialValue: [] });
  usersLoading = toSignal(select(ProjectTeamSelectors.usersLoading), { initialValue: false });
  user = toSignal(select(UserSelectors.user));
  sasUrl = toSignal(select(UserSelectors.sasRealUrlForImages), { initialValue: null });

  mainProject = toSignal(this.mainProject$);
  mainProjectLoading = toSignal(select(ProjectSelectors.mainProjectLoading), { initialValue: false });
  mainProjectAdmins = toSignal(select(ProjectSelectors.mainProjectAdmins), { initialValue: [] });
  existingAdmins = computed(() =>
    this.mainProjectAdmins().filter((admin) => admin.status === this.mainProjectAdminStatus.Active),
  );
  callerAdmin = computed(() => this.existingAdmins().find((a) => a.userId === this.user()?.id));
  ownerAdmins = computed(() =>
    this.mainProjectAdmins().filter((a) =>
      this.mainProject()?.coOwnerOrganizationId
        ? a.organizationId !== this.mainProject()?.coOwnerOrganizationId
        : a.organizationId === this.mainProject()?.ownerOrganizationId,
    ),
  );
  invitedOwnerAdmins = computed(() => this.ownerAdmins().filter((a) => a.status === MainProjectAdminStatus.Invited));
  coOwnerAdmins = computed(() =>
    this.mainProjectAdmins().filter((a) =>
      this.mainProject()?.ownerOrganizationId
        ? a.organizationId !== this.mainProject()?.ownerOrganizationId
        : a.organizationId === this.mainProject()?.coOwnerOrganizationId,
    ),
  );
  invitedCoOwnerAdmins = computed(() =>
    this.coOwnerAdmins().filter((a) => a.status === MainProjectAdminStatus.Invited),
  );
  availableOwnerAdmins = computed(() => {
    const currentAdmins = this.existingAdmins()
      .filter(
        (admin) =>
          admin.organizationId === this.store.selectSnapshot(ProjectSelectors.mainProject)?.ownerOrganizationId,
      )
      .map((user) => user.userId);
    const allUsers = this.store.selectSnapshot(OrganisationSelectors.users);
    return allUsers.filter((user) => !currentAdmins.includes(user.id));
  });

  availableCoOwnerAdmins = computed(() => {
    const currentAdmins = this.existingAdmins()
      .filter(
        (admin) =>
          admin.organizationId === this.store.selectSnapshot(ProjectSelectors.mainProject)?.coOwnerOrganizationId,
      )
      .map((user) => user.userId);
    const allUsers = this.store.selectSnapshot(OrganisationSelectors.users);
    return allUsers.filter((user) => !currentAdmins.includes(user.id));
  });

  dialogData = signal<DialogData | undefined>(undefined);
  ownersRawData = signal<{ [key: string]: any } | null>(null);

  nameControl = new FormControl<string>('', [FormUtility.requiredValidator(), FormUtility.maxLengthValidator(100)]);
  codeControl = new FormControl<string>('', [
    FormUtility.requiredValidator(),
    FormUtility.minLengthValidator(2),
    FormUtility.maxLengthValidator(5),
  ]);
  userControl = new FormControl<string>('', [FormUtility.requiredValidator()]);
  ownerSubscriptionsArray = new FormArray<
    FormGroup<{
      id: FormControl<string>;
      currentCollaborator: FormControl<string>;
      projectId: FormControl<string>;
      name: FormControl<string>;
      code: FormControl<string>;
      collaborators: FormControl<CollaboratorModel[]>;
    }>
  >([]);

  searchUsers = new FormControl(null);

  inviteOwnerForm = this.fb.group({
    userId: new FormControl<string | undefined>(''),
    email: new FormControl<string | undefined>('', [FormUtility.emailValidator('Please use a valid email address.')]),
    message: [''],
  });

  get ownersArrayRawData() {
    return this.ownerSubscriptionsArray.getRawValue();
  }

  get unSavedChanges() {
    return !isEqual(this.ownersRawData(), this.ownersArrayRawData);
  }

  get inviteOwnerControls() {
    return this.inviteOwnerForm.controls;
  }

  get isSendInviteDisabled() {
    return (
      this.inviteOwnerForm.invalid || (!this.inviteOwnerControls.email.value && !this.inviteOwnerControls.userId.value)
    );
  }

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogData,
  ) {
    this.dialogData.set(data);
    this.nameControl.setValue(data.mainProject.name);
    this.codeControl.setValue(data.mainProject.code);
    this.store.dispatch(new GetMainProject(data.mainProject.id));
  }

  ngOnInit() {
    this.resetSearch();
    this.mainProject$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((mainProject) => {
      this.clearOwnerControlArray();
      if (mainProject) {
        mainProject.projects.map((project) => {
          const collaborators = [...project.collaborators];
          if (
            mainProject.coOwnerOrganization &&
            !collaborators.find((c) => c.organizationId === mainProject.coOwnerOrganizationId)
          ) {
            const { id, name, isActive, primaryColor } = mainProject.coOwnerOrganization;
            collaborators.push({
              id: uuidv4(),
              organizationId: id,
              collaboratorState: isActive ? CollaboratorState.Active : CollaboratorState.Inactive,
              email: '',
              collaboratorType: CollaboratorType.Client,
              name: name || '',
              primaryColor: primaryColor || '',
            });
          }

          const collaboratorId =
            (collaborators || []).find((c) => c.organizationId === project.subscriptionOwnerOrganizationId)?.id || '';

          const control = this.fb.group({
            id: new FormControl(collaboratorId, { nonNullable: true }),
            currentCollaborator: [collaboratorId],
            projectId: [project.id],
            name: [project.name],
            code: [project.code],
            collaborators: [collaborators],
          });
          this.ownerSubscriptionsArray.push(control);
        });

        this.ownersRawData.set(this.ownerSubscriptionsArray.getRawValue());
      }
    });

    this.searchUsers.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: string | null) => {
      if (value) {
        const guidPattern =
          /(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}/gi;
        if (value.match(guidPattern)) {
          this.inviteOwnerControls.userId.setValue(value);
          this.inviteOwnerControls.email.reset('');
        } else {
          this.inviteOwnerControls.email.setValue(value);
          this.inviteOwnerControls.email.markAsTouched();
          this.cd.markForCheck();
        }
      } else {
        this.inviteOwnerControls.userId.setValue('');
        this.inviteOwnerControls.email.setValue('');
      }
    });
  }

  clearOwnerControlArray() {
    while (this.ownerSubscriptionsArray.length !== 0) {
      this.ownerSubscriptionsArray.removeAt(0);
    }
  }

  onCancel() {
    this.matDialogRef.close();
  }

  handleAdminRemoval(admin: MainProjectAdmin) {
    // CoOwner endpoint should be called only when last admin is left
    // CoOwner endpoint should also not be called on the pending invites
    this.coOwnerAdmins().find((a) => a.id === admin.id) &&
    this.coOwnerAdmins().length === 1 &&
    admin.status !== MainProjectAdminStatus.Invited
      ? this.onRemoveCoOwner(admin)
      : this.onRemoveAdmin(admin);
  }

  onRemoveAdmin(admin: MainProjectAdmin) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: 473,
      data: {
        title: admin.status === MainProjectAdminStatus.Invited ? 'Withdraw invitation?' : 'Remove Admin?',
        text:
          admin.status === MainProjectAdminStatus.Invited
            ? 'The main project group admin and their organization will lose access to the main project group if you withdraw the invitation.'
            : 'Are you sure you want to remove the admin from this main project group? They might lose access to this setting.',
        saveButtonText: admin.status === MainProjectAdminStatus.Invited ? 'Withdraw invitation' : 'Remove Admin',
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.store.dispatch(new RemoveProjectMainAdmin(admin));
      }
    });
  }

  onRemoveCoOwner(admin: MainProjectAdmin) {
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: 473,
      data: {
        title: 'Remove Co-owner?',
        text: 'If you remove the co-owner, the users set as main project group admins will lose access to their admin rights. Are you sure you want to continue?',
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.store.dispatch(new RemoveCoOwner(admin));
      }
    });
  }

  onAddAdmin() {
    const payload: MainProjectAdminPayload = {
      userId: this.userControl.value || '',
      mainProjectId: this.store.selectSnapshot(ProjectSelectors.mainProject)?.id || '',
    };
    this.store.dispatch(new AddProjectMainAdmin(payload));
    this.userControl.reset('');
  }

  inviteAdmin() {
    const body = this.inviteOwnerForm.getRawValue() as InviteMainProjectAdminPayload;
    if (!body.email) {
      delete body.email;
    }

    if (!body.userId) {
      delete body.userId;
    }

    this.store.dispatch(
      new InviteProjectMainAdmin({
        ...body,
        mainProjectId: this.store.selectSnapshot(ProjectSelectors.mainProject)?.id || '',
      }),
    );
  }

  onCancelOwnerShipChange() {
    this.ownerSubscriptionsArray.controls.forEach((control) => {
      if (control.dirty) {
        control.get('id')?.reset(control.get('currentCollaborator')?.value);
      }
    });
  }

  onConfirmOwnerShipChange() {
    this.ownerSubscriptionsArray.controls.map((control) => {
      if (control.dirty) {
        const collaborators = control.get('collaborators')?.value as CollaboratorModel[];
        const findCollaborator = collaborators.find((c) => c.id === control.get('id')?.value);
        const projectId = control.get('projectId')?.value;
        if (projectId) {
          this.store.dispatch(
            new UpdateProject(projectId, {
              subscriptionOwnerOrganizationId: findCollaborator?.organizationId,
            }),
          );
        }
      }
    });
    this.ownerSubscriptionsArray.markAsUntouched();
    this.ownerSubscriptionsArray.markAsPristine();
  }

  onSaved(control: FormControl<string | null>) {
    const mainProjectId = this.store.selectSnapshot(ProjectSelectors.mainProject)?.id;
    const val = control.value;
    if (control.valid && mainProjectId && val) {
      this.store.dispatch(new UpdateMainProject(mainProjectId, { name: val }));
    }
  }

  resetSearch() {
    // reset search to blank
    this.store.dispatch(new SetUsersQuery({ ...organisationsUsersQuery, searchTerm: '' }));
  }

  onSearchUsers(event: string, owners: boolean) {
    if (event.includes('@')) {
      this.store.dispatch(
        new SetUsersQuery({
          ...organisationsUsersQuery,
          searchTerm: event,
          excludeOrganizationId: [
            (owners ? this.mainProject()?.coOwnerOrganizationId : this.mainProject()?.ownerOrganizationId) || '',
          ],
        }),
      );
    } else {
      this.resetSearch();
    }
  }

  handleCoOwnerAdmin() {
    const hasCoOwner = this.mainProjectAdmins().find(
      (mpa) => mpa.organizationId === this.mainProject()?.coOwnerOrganizationId,
    );
    hasCoOwner ? this.onAddAdmin() : this.inviteAdmin();
  }

  handleOwnerAdmin() {
    const hasCoOwner = this.mainProjectAdmins().find(
      (mpa) => mpa.organizationId === this.mainProject()?.ownerOrganizationId,
    );
    hasCoOwner ? this.onAddAdmin() : this.inviteAdmin();
  }
}
